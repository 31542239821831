import { forEach, find } from 'lodash';
import { Mixin } from '../../mixins/mixin.decorator';
import { FormGroup, Validators } from '@angular/forms';
import { iFieldForm } from '@app/interface/field-form.interfaces';
import { ValidationService } from '@intm-ui/message-helper';
import { FormBuilderMethodsClass } from '../form-builder.methods.class';
import { AccountComponent } from '@app/component/private/account';
import { IUser } from '@app/interface/user.interface';

@Mixin([FormBuilderMethodsClass])
export class AccountFormClass
    extends FormGroup
    implements FormBuilderMethodsClass
{
    title: iFieldForm = {
        key: 'title',
        value: '',
        label: 'Tratamiento',
        placeholder: 'Tratamiento',
        controlType: 'select',
        validators: [Validators.required],
        settings: {},
        options: [],
    };
    name: iFieldForm = {
        key: 'name',
        value: '',
        label: 'Nombre',
        placeholder: 'Nombre',
        controlType: 'text',
        validators: [
            Validators.required,
            Validators.maxLength(30),
            ValidationService.firstLastName,
        ],
        settings: {},
        options: [],
    };
    surname: iFieldForm = {
        key: 'surname',
        value: '',
        label: 'apellidos',
        placeholder: 'Apellidos',
        controlType: 'text',
        validators: [
            Validators.required,
            Validators.maxLength(30),
            ValidationService.firstLastName,
        ],
        settings: {},
        options: [],
    };
    day: iFieldForm = {
        key: 'day',
        value: '',
        label: 'Dias',
        placeholder: 'DD',
        controlType: 'select',
        validators: [Validators.required],
        settings: {},
        options: [],
    };
    month: iFieldForm = {
        key: 'month',
        value: '',
        label: 'Meses',
        placeholder: 'MM',
        controlType: 'select',
        validators: [Validators.required],
        settings: {},
        options: [],
    };
    year: iFieldForm = {
        key: 'year',
        value: '',
        label: 'Anios',
        placeholder: 'YYYY',
        controlType: 'select',
        validators: [Validators.required],
        settings: {},
        options: [],
    };
    country: iFieldForm = {
        key: 'country',
        value: '',
        label: 'Pais',
        placeholder: 'País',
        controlType: 'select',
        validators: [Validators.required],
        settings: {},
        options: [],
    };
    province: iFieldForm = {
        key: 'province',
        value: '',
        label: 'Provincia',
        placeholder: 'Provincia',
        controlType: 'select',
        validators: [Validators.required],
        settings: {},
        options: [],
    };
    locality: iFieldForm = {
        key: 'locality',
        value: '',
        label: 'Localidad',
        placeholder: 'Localidad',
        controlType: 'text',
        validators: [
            Validators.required,
            Validators.maxLength(40),
            ValidationService.onlyLettersAndNumbers,
        ],
        settings: {},
        options: [],
    };
    address: iFieldForm = {
        key: 'address',
        value: '',
        label: 'Direccion',
        placeholder: 'Dirección',
        controlType: 'text',
        validators: [Validators.required, Validators.maxLength(60)],
        settings: {},
        options: [],
    };
    postalCode: iFieldForm = {
        key: 'postalCode',
        value: '',
        label: 'codigo_postal',
        placeholder: 'C.P',
        controlType: 'text',
        validators: [
            Validators.required,
            Validators.maxLength(10),
            ValidationService.codicePostal,
        ],
        settings: {},
        options: [],
    };
    phone: iFieldForm = {
        key: 'phone',
        value: '',
        label: 'telefono',
        placeholder: 'Teléfono',
        controlType: 'number',
        validators: [
            Validators.required,
            Validators.maxLength(30),
            ValidationService.numberphoneValidator,
        ],
        settings: {},
        options: [],
    };
    email: iFieldForm = {
        key: 'email',
        value: '',
        label: 'Email',
        placeholder: 'Email',
        controlType: 'email',
        validators: [
            Validators.required,
            ValidationService.emailValidator,
            Validators.maxLength(60),
        ],
        settings: {},
        options: [],
    };
    documentType: iFieldForm = {
        key: 'documentType',
        value: '',
        label: 'tipo_documento',
        placeholder: 'tipo_documento',
        controlType: 'text',
        validators: [],
        settings: {},
        options: [],
    };
    documentNumber: iFieldForm = {
        key: 'documentNumber',
        value: '',
        label: 'numero_documento',
        placeholder: 'numero_documento',
        controlType: 'text',
        validators: [Validators.required],
        settings: {},
        options: [],
        disabled: true,
    };

    baseForms = {
        ACCOUNT: [
            this.title,
            this.name,
            this.surname,
            this.day,
            this.month,
            this.year,
            this.country,
            this.province,
            this.locality,
            this.address,
            this.postalCode,
            this.phone,
            this.email,
            this.documentType,
            this.documentNumber,
        ],
    };
    model: AccountComponent;
    _getForm: (
        formType: string,
        returnNewFormGroup?: boolean,
        extraSettings?: any
    ) => FormGroup;

    constructor(
        accountComponent: AccountComponent,
        extraSettings?: any,
    ) {
        super(extraSettings || {});
        this.model = accountComponent;
        this.initForm();
    }

    initForm() {
        this.controls = {};
        this._getForm('ACCOUNT');
        this.loadData();
    }

    isValid(): boolean {
        forEach(this.controls, (control) => {
            control.markAsDirty();
            control.markAsTouched();
        });

        return this.valid;
    }

    loadData() {
        let data = this.getDataToFormInfo(this.model.userInfo);

        this.controls['title'].patchValue({
            value: data.title,
            text: data.title,
        });
        this.controls['name'].patchValue(data.name);
        this.controls['surname'].patchValue(data.surname);
        this.controls['documentNumber'].patchValue(data.documentNumber);
        this.controls['documentNumber'].disable();
        this.controls['day'].patchValue({ value: data.day, text: data.day });
        this.controls['month'].patchValue({
            value: data.month,
            text: data.month,
        });
        this.controls['year'].patchValue({ value: data.year, text: data.year });
        this.controls['country'].patchValue({
            value: data.country,
            text: data.country?.name,
        });
        this.controls['province'].patchValue({
            value: data.province,
            text: data.province?.name,
        });
        this.controls['locality'].patchValue(data.locality);
        this.controls['address'].patchValue(data.address);
        this.controls['postalCode'].patchValue(data.postalCode);
        this.controls['phone'].patchValue(data.phone);
        this.controls['email'].patchValue(data.email);
        data.country = this.model.countries.find((option:any) => option.value.idDyn == data.country?.idDyn)
        if (data.country) {
            this.model.dataService.setProvinceByCountry(data.country);
        }
    }

    getDataToFormInfo(userInfo: IUser) {
        let userValues = {
            title: userInfo.title ? userInfo.title : '',
            name: userInfo.name ? userInfo.name : '',
            surname: userInfo.surname ? userInfo.surname : '',
            documentNumber: userInfo.documentNumber
                ? userInfo.documentNumber
                : '',
            day: '',
            month: '',
            year: '',
            country:
                userInfo.addressInfoList && userInfo.addressInfoList.length > 0
                    ? userInfo.addressInfoList[0].commercialCountry
                    : {},
            province:
                userInfo.addressInfoList && userInfo.addressInfoList.length > 0
                    ? userInfo.addressInfoList[0].commercialProvince
                    : {},
            locality:
                userInfo.addressInfoList && userInfo.addressInfoList.length > 0
                    ? userInfo.addressInfoList[0].commercialCity
                    : '',
            address:
                userInfo.addressInfoList && userInfo.addressInfoList.length > 0
                    ? userInfo.addressInfoList[0].commercialAddress
                    : '',
            postalCode:
                userInfo.addressInfoList && userInfo.addressInfoList.length > 0
                    ? userInfo.addressInfoList[0].commercialPostalCode
                    : '',
            phone:
                userInfo.contactInfoList && userInfo.contactInfoList.length > 0
                    ? userInfo.contactInfoList[0].mobileNumber
                    : '',
            email:
                userInfo.contactInfoList && userInfo.contactInfoList.length > 0
                    ? userInfo.contactInfoList[0].email
                    : '',
        };

        let birthDate = userInfo.birthDate ? userInfo.birthDate.split('-') : [];
        if (birthDate.length > 0) {
            userValues.day = birthDate[2];
            userValues.month = birthDate[1];
            userValues.year = birthDate[0];
        }

        return userValues;
    }
}
