import { Mixin } from '../../mixins/mixin.decorator';
import { FormGroup, Validators } from '@angular/forms';
import { iFieldForm } from '@app/interface/field-form.interfaces';
import { ValidationService } from '@intm-ui/message-helper';
import { FormBuilderMethodsClass } from '../form-builder.methods.class';
import { FormControlIntermundial } from '../form-control-intermundial.class';
import * as _ from 'lodash';


@Mixin([FormBuilderMethodsClass])
export class PasswordFormClass extends FormGroup implements FormBuilderMethodsClass {
    // campos par la contrasenia
    oldPassword: iFieldForm = {
        key: 'oldPassword',
        value: '',
        label: 'Antigua contraseña',
        placeholder: 'Antigua contraseña',
        validators: [Validators.required],
        settings: {},
        options: []
    };
    newPassword: iFieldForm = {
        key: 'newPassword',
        value: '',
        label: 'Nueva contraseña',
        placeholder: 'Nueva contraseña',
        controlType: 'password',
        validators: [Validators.required, ValidationService.validatePassword, this.validateConfirmPassword() ],
        settings: {},
        options: []
    };
    confirmPassword: iFieldForm = {
        key: 'confirmPassword',
        value: '',
        label: 'Repetir contraseña',
        placeholder: 'Nueva contraseña',
        controlType: 'password',
        validators: [Validators.required, this.validatePassword()],
        settings: {},
        options: []
    };
    baseForms = {
        'PASSWORD': [
            this.oldPassword,
            this.newPassword,
            this.confirmPassword
        ]
    };
    model: any;
    _getForm: (tipoFormulario: string, returnNewFormGroup?: boolean, extraSettings?: any) => FormGroup;

    constructor(extraSettings?: any) {
        super(extraSettings || {});
        this.initForm();
    }
    initForm() {
        this.controls = {} ;
        this._getForm('PASSWORD');
    }
    isValid(): boolean {
        _.forEach(this.controls, (control) => {
            control.markAsDirty();
            control.markAsTouched();
        });

        return this.valid;
    }
    validatePassword() {
        return (control: FormControlIntermundial): { [key: string]: any } | null => {
            if (control.value) {
                if(this.controls['newPassword'].value !== control.value){
                    return {
                        passwordnotMatch: true
                    }
                }
            }
            return null;
        }
    }

    validateConfirmPassword(){
        return (control: FormControlIntermundial): { [key: string]: any } | null => {
            if (this.controls['confirmPassword']) {
                this.controls['confirmPassword'].updateValueAndValidity();
            }
            return null;
        }
    }
}
