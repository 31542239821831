<!-- header -->
<div class="flex justify-between items-end mb-24 xs:mb-16">
    <intm-text-high-s>
        {{ 'cuenta.titulo_micuenta' | translate }}
    </intm-text-high-s>
    <div class="hidden s:block">
        <intm-text-low-m (click)="logout()" class="hover:cursor-pointer hover:opacity-75 active:opacity-50">
            <span class="text-semantic-primary">
                {{ 'menu.logout' | translate }}
            </span>
        </intm-text-low-m>
    </div>
</div>
<!-- body -->
<div class="w-full flex flex-col gap-16">
    <div class="shadow-stroke-low p-24 rounded-16 flex flex-col gap-24 s:px-16 s:gap-16">
        <intm-text-high-xs>{{ 'cuenta.subtitulo_datospersonales' | translate }}</intm-text-high-xs>
        @if (accountDataForm) {
            <form class="flex flex-col gap-24 s:gap-16">
                <div class="grid grid-cols-[120px_1fr_1fr] gap-16 xs:grid-cols-[106px_1fr] xs:gap-x-8">
                    <div>
                        <intm-simple-label>{{ 'cuenta.label_tratamiento' | translate }}</intm-simple-label>
                        <intm-select
                            [options]="titles"
                            placeholder="{{ 'cuenta.placeholder_tratamiento' | translate }}"
                            [formControl]="accountDataForm.controls.title"
                            >
                        </intm-select>
                    </div>
                    <div>
                        <intm-simple-label>{{ 'cuenta.label_nombre' | translate }}</intm-simple-label>
                        <intm-text-input
                            placeholder="{{ 'cuenta.placeholder_nombre' | translate }}"
                            name="name"
                            id="name"
                            [formControl]="accountDataForm.controls.name"
                        ></intm-text-input>
                    </div>
                    <div class="xs:col-span-full">
                        <intm-simple-label>{{ 'cuenta.label_apellidos' | translate }}</intm-simple-label>
                        <intm-text-input
                            placeholder="{{ 'cuenta.placeholder_apellidos' | translate }}"
                            name="surname"
                            id="surname"
                            [formControl]="accountDataForm.controls.surname"
                            class="s:col-span-full"
                        ></intm-text-input>
                    </div>
                </div>
                <div>
                    <intm-simple-label>{{ 'cuenta.label_numerodocumento' | translate }}</intm-simple-label>
                    <intm-text-input
                        name="number-document"
                        id="number-document"
                        [formControl]="accountDataForm.controls.documentNumber"
                    ></intm-text-input>
                </div>
                <div class="flex flex-col">
                    <intm-simple-label>{{ 'cuenta.label_fechanacimiento' | translate }}</intm-simple-label>
                    <div class="grid grid-cols-[1fr_1fr_1fr] gap-16 xs:grid-cols-[1fr_1fr] xs:gap-8">
                        <div>
                            <intm-select
                                [options]="dataService.daysList"
                                placeholder="{{ 'cuenta.placeholder_dia' | translate }}"
                                name="day"
                                [formControl]="accountDataForm.controls.day"
                                >
                            </intm-select>
                        </div>
                        <div>
                            <intm-select
                                [options]="dataService.monthsList"
                                placeholder="{{ 'cuenta.placeholder_mes' | translate }}"
                                name="month"
                                [formControl]="accountDataForm.controls.month"
                                >
                            </intm-select>
                        </div>
                        <div class="xs:col-span-full">
                            <intm-select
                                [options]="dataService.yearsList"
                                placeholder="{{ 'cuenta.placeholder_anio' | translate }}"
                                name="year"
                                [formControl]="accountDataForm.controls.year"
                                >
                            </intm-select>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-[1fr_1fr_1fr] gap-16 s:grid-cols-[1fr]">
                    <div>
                        <intm-simple-label>{{ 'cuenta.label_pais' | translate }}</intm-simple-label>
                        <intm-select
                            [options]="countries"
                            placeholder="{{ 'cuenta.placeholder_pais' | translate }}"
                            name="country"
                            id="country"
                            [formControl]="accountDataForm.controls.country"
                            (change)="setProvince($event)"
                            >
                        </intm-select>
                    </div>
                    <div>
                        <intm-simple-label>{{ 'cuenta.label_provincia' | translate }}</intm-simple-label>
                        <intm-select
                            #selectProvince
                            [options]="dataService.provincesList"
                            placeholder="{{ 'cuenta.placeholder_provincia' | translate }}"
                            name="province"
                            [formControl]="accountDataForm.controls.province"
                            >
                        </intm-select>
                    </div>
                    <div>
                        <intm-simple-label>{{ 'cuenta.label_localidad' | translate }}</intm-simple-label>
                        <intm-text-input
                            placeholder="{{ 'cuenta.placeholder_localidad' | translate }}"
                            name="locality"
                            id="locality"
                            [formControl]="accountDataForm.controls.locality"
                        ></intm-text-input>
                    </div>
                </div>
                <div class="grid grid-cols-[1fr_1fr] gap-16 s:grid-cols-[1fr]">
                    <div>
                        <intm-simple-label>{{ 'cuenta.label_direccion' | translate }}</intm-simple-label>
                        <intm-text-input
                            placeholder="{{ 'cuenta.placeholder_direccion' | translate }}"
                            name="address"
                            id="address"
                            [formControl]="accountDataForm.controls.address"
                        ></intm-text-input>
                    </div>
                    <div>
                        <intm-simple-label>{{ 'cuenta.label_codigopostal' | translate }}</intm-simple-label>
                        <intm-text-input
                            placeholder="{{ 'cuenta.placeholder_codigopostal' | translate }}"
                            name="postal-code"
                            id="postal-code"
                            [formControl]="accountDataForm.controls.postalCode"
                        ></intm-text-input>
                    </div>
                </div>
                <div class="grid grid-cols-[1fr_1fr] gap-16 s:grid-cols-[1fr]">
                    <div>
                        <intm-simple-label>{{ 'cuenta.label_telefono' | translate }}</intm-simple-label>
                        <intm-text-input
                            placeholder="{{ 'cuenta.placeholder_telefono' | translate }}"
                            name="phone"
                            id="phone"
                            [formControl]="accountDataForm.controls.phone"
                        ></intm-text-input>
                    </div>
                    <div>
                        <intm-simple-label>{{ 'cuenta.label_email' | translate }}</intm-simple-label>
                        <intm-email-input
                            placeholder="{{ 'cuenta.placeholder_email' | translate }}"
                            name="email"
                            id="email"
                            [formControl]="accountDataForm.controls.email"
                        ></intm-email-input>
                    </div>
                </div>

                <div class="flex w-full s:mt-8 xs:mt-0">
                    <intm-button-primary id="btnSaveAccount" [size]="'small'" [block]="true" (click)="submitAccountForm()"
                        class="w-[233px] xs:w-full"
                    >
                        <intm-text-low-m [color]="'white'">
                            {{ 'cuenta.btn_datoscuenta_guardar' | translate }}
                        </intm-text-low-m>
                    </intm-button-primary>
                </div>
            </form>
        }
    </div>
    <div class="shadow-stroke-low p-24 rounded-16 flex flex-col gap-24 s:px-16">
        <intm-text-high-xs>{{ 'cuenta.subtitulo_password' | translate }}</intm-text-high-xs>
        @if (changePasswordForm) {
            <form class="flex flex-col gap-24 s:gap-16">
                <div>
                    <intm-simple-label>{{ 'cuenta.label_antiguapassword' | translate }}</intm-simple-label>
                    <intm-password-input
                        placeholder="{{ 'cuenta.placeholder_antiguapassword' | translate }}"
                        name="oldPassword"
                        id="oldPassword"
                        [formControl]="changePasswordForm.controls['oldPassword']"
                    ></intm-password-input>
                </div>
                <div class="grid grid-cols-[1fr_1fr] gap-16 s:grid-cols-[1fr]">
                    <div>
                        <intm-simple-label>{{ 'cuenta.label_nuevapassword' | translate }}</intm-simple-label>
                        <intm-password-input
                            placeholder="{{ 'cuenta.placeholder_nuevapassword' | translate }}"
                            name="newPassword"
                            id="newPassword"
                            [formControl]="changePasswordForm.controls['newPassword']"

                        ></intm-password-input>
                    </div>
                    <div>
                        <intm-simple-label>{{ 'cuenta.label_confirmarpassword' | translate }}</intm-simple-label>
                        <intm-password-input
                            placeholder="{{ 'cuenta.placeholder_confirmarpassword' | translate }}"
                            name="confirmPassword"
                            id="confirmPassword"
                            [formControl]="changePasswordForm.controls['confirmPassword']"
                        ></intm-password-input>
                    </div>
                </div>
                <div class="flex w-full s:mt-8 xs:mt-0">
                    <intm-button-primary id="btnSavePasswords" [size]="'small'" [block]="true" (click)="submitChangePasswordForm()"
                        class="w-[233px] xs:w-full"
                    >
                        <intm-text-low-m [color]="'white'">
                            {{ 'cuenta.btn_password_guardar' | translate }}
                        </intm-text-low-m>
                    </intm-button-primary>
                </div>
            </form>
        }
    </div>
    <div class="shadow-stroke-low p-24 rounded-16 flex flex-col gap-24 s:px-16">
        <intm-text-high-xs>{{ 'cuenta.subtitulo_eliminarcuenta' | translate }}</intm-text-high-xs>
        <intm-text-low-s [color]="'mid'">
            {{ 'cuenta.label_eliminarcuenta' | translate }}
        </intm-text-low-s>
        <div class="flex w-full">
            <intm-button-danger [size]="'small'" [block]="true" (click)="openModal('modal1')" class="w-[233px] xs:w-full">
                <intm-text-low-m [color]="'white'">
                    {{ 'cuenta.btn_eliminar_cuenta' | translate }}
                </intm-text-low-m>
            </intm-button-danger>
        </div>
    </div>
    <intm-modal [id]="'modal1'">
        <intm-modal-header>
            <intm-text-high-xs>
                {{ 'cuenta.modal_titulo_eliminarcuenta' | translate }}
            </intm-text-high-xs>
        </intm-modal-header>
        <intm-modal-body class="flex flex-col gap-24">
            <intm-text-low-s [color]="'mid'">
                <div class="flex flex-col gap-24" [innerHTML]="'cuenta.modal_descripcion_eliminarcuenta' | translate"></div>
            </intm-text-low-s>
        </intm-modal-body>
        <intm-modal-footer>
            <div class="grid grid-cols-2 gap-8 xs:grid-cols-1">
                <intm-button-danger [block]="true" (click)="deleteAccount()">
                    <intm-text-low-m [color]="'white'">
                        {{ 'cuenta.modal_btn_eliminarcuenta' | translate }}
                    </intm-text-low-m>
                </intm-button-danger>
                <intm-button-secondary [block]="true" (click)="closeModal('modal1')">
                    <intm-text-low-m>
                        {{ 'cuenta.modal_btn_eliminarcuenta_atras' | translate }}
                    </intm-text-low-m>
                </intm-button-secondary>
            </div>
        </intm-modal-footer>
    </intm-modal>
</div>
