import { Component } from "@angular/core";

@Component({
  selector: "app-delete-account-email",
  template: `
    <div class="min-h-screen grid grid-rows-[auto_1fr] gap-[64px]">
        <app-topbar [hasBorder]="true"></app-topbar>
         <app-success-message
            [iconUrl]="'assets/images/icons/success-message/emails.svg'"
            [title]="'cuenta.modal_titulo_emailenviado' | translate"
            [description]="'cuenta.modal_descripcion_emailenviado' | translate"
            [buttonText]="'cuenta.modal_btn_emailenviado' | translate"
            [buttonUrl]="'account'"
         ></app-success-message>
    </div>
  `,
  host: { class: 'w-full' }
})
export class DeleteAccountEmailComponent  {
    constructor() { }
}