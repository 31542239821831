import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ISelectOption } from '@app/interface/ui';
import { IUser } from '@app/interface/user.interface';
import { AccountService } from '@app/service/account';
import { AuthService } from '@app/service/auth';
import {
    DataService,
    LangService,
    LoaderService,
    NotificationService,
    StorageService,
} from '@app/service/utils';
import { ModalService } from '@intm-ui/modals';
import { Select } from '@intm-ui/select/select.component';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'lodash';
import { AccountFormClass } from 'src/app/class/form-builder/account/account-form.class';
import { PasswordFormClass } from 'src/app/class/form-builder/account/password-form.class';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
})
export class AccountComponent implements OnInit {
    //servicio que se encarga de cargar los datos de fechas, tratamiento, tipo documento, paises y provincias
    public dataService = inject(DataService);

    @ViewChild('selectProvince') selectProvince!: Select;

    // Forms
    public accountDataForm: AccountFormClass;
    public changePasswordForm: PasswordFormClass;

    userId: any;
    userInfo: any;
    countries: any[] = [];
    titles:ISelectOption[] = [];

    texts: {
        invalidForm: string;
        updateAccountSuccess: string;
        updatePasswordSuccess: string;
    };

    constructor(
        private authService: AuthService,
        private accountService: AccountService,
        public storageService: StorageService,
        private loaderService: LoaderService,
        private notificationService: NotificationService,
        private modalService: ModalService,
        private translateService: TranslateService,
        private router: Router
    ) {}

    ngOnInit() {
        this.loaderService.show();
        this.translateService
            .get([
                'cuenta.formulario_invalido',
                'cuenta.actualizardatos_exito',
                'cuenta.actualizarpassword_exito',
            ])
            .subscribe((texts: any) => {
                this.texts = {
                    invalidForm: texts['cuenta.formulario_invalido'],
                    updateAccountSuccess: texts['cuenta.actualizardatos_exito'],
                    updatePasswordSuccess: texts['cuenta.actualizarpassword_exito']
                };
            });

        this.dataService.getTitleList().then((response: any) => {
            this.titles = response;
        });
        this.dataService.getCountries().then((response: any) => {
            this.countries = response;
            this.userId = this.authService.getUserInfo().id;
            this.accountService.getUserInfo(this.userId).subscribe((user) => {
                this.userInfo = user;
                this.initForms();
                this.loaderService.hide();
            })
        });
    }

    initForms() {
        this.accountDataForm = new AccountFormClass(this);
        this.changePasswordForm = new PasswordFormClass();
    }

    submitAccountForm() {
        if (this.accountDataForm.isValid()) {
            this.loaderService.show();

            let data = this.accountDataForm.value;
            let user: IUser = {
                addressInfoList: [
                    {
                        commercialAddress: data.address,
                        commercialCity: data.locality,
                        commercialCountry: {
                            idDyn: data.country.value.idDyn,
                            isoCode3: data.country.value.isoCode3,
                            name: data.country.value.name,
                        },
                        commercialPostalCode: data.postalCode,
                        commercialProvince: data.province.value,
                    },
                ],
                birthDate: `${data.year.value}-${data.month.value}-${data.day.value}`,
                contactInfoList: [
                    {
                        email: data.email,
                        mobileNumber: data.phone,
                    },
                ],
                documentNumber: this.userInfo.documentNumber,
                documentType: this.userInfo.documentType,
                id: this.userInfo.id,
                name: data.name,
                privacyPolicyChecked: 1,
                surname: data.surname,
                title: data.title.value,
            };

            this.accountService.updateUser(user, this.userInfo.id).subscribe(
                (response: any) => {
                    this.loaderService.hide();
                    this.notificationService.success(
                        this.texts.updateAccountSuccess,
                        3000
                    );
                },
                (error: any) => {
                    console.log(error);
                    this.loaderService.hide();
                    this.notificationService.error(
                        'Error ref: ' + error.error.reference,
                        3000
                    );
                }
            );
        } else {
            this.notificationService.error(this.texts.invalidForm, 3000);
        }
    }

    public setProvince(args: any) {
        this.accountDataForm.controls['province'].setValue(null);
        this.selectProvince.clearValue();
        this.dataService.setProvinceByCountry(args.value);
    }

    /**
     * Función que se encarga de enviar los datos de cambio de contraseña
     */
    submitChangePasswordForm() {
        // Validamos el formulario de cambio de contrasenia
        if (this.changePasswordForm.isValid()) {
            // Si el formulario es valido mostramos el spinner de carga
            this.loaderService.show();

            // Obtenemos info del formulario
            let data = this.changePasswordForm.value;

            // Armamos el cuerpo de la petición con los datos del usuario
            // mas los del formulario
            const body: {
                id: number;
                username: string;
                oldPassword: string;
                newPassword: string;
                confirmPassword: string;
            } = {
                id: this.userInfo.id,
                username: this.userInfo.username,
                oldPassword: data.oldPassword,
                newPassword: data.newPassword,
                confirmPassword: data.confirmPassword,
            };
            // Llamamos al servicio de cambio de contraseña
            this.accountService.changePassword(body).subscribe(
                (resp: any) => {
                    // Cuando el servicio responde ocultamos el spinner de carga
                    this.loaderService.hide();
                    // Mostramos un mensaje al usuario indicando que se ha cambiado con éxito
                    this.notificationService.success(
                        this.texts.updatePasswordSuccess,
                        3000
                    );
                },
                (error: any) => {
                    // En caso de que ocurra un error obtenemos el error y lo mostramos
                    this.loaderService.hide();
                    this.notificationService.error(error.error.message, 4000);
                }
            );
        } else {
            // En caso de que el formulario no es valido lanzamos una notificacion al usuario
            // Informadole que debe de llenar los campos en rojo
            this.notificationService.error(this.texts.invalidForm, 3000);
        }
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    deleteAccount() {
        this.loaderService.show();
        this.accountService.getDeleteUserEmail().subscribe((response) => {
            this.closeModal('modal1');
            this.loaderService.hide();
            this.router
                .navigate([
                    `${LangService.getLang()}/delete-account/email-sent`,
                ])
                .then();
        });
    }

    logout() {
        this.loaderService.show();
        this.authService.logout().subscribe((resp) => {
            this.router.navigate([`${LangService.getLang()}/login`]).then();
            this.loaderService.hide();
        });
    }
}
